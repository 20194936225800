import {
    Button,
    Dropdown,
    DropdownHeader,
    DropdownItem,
    DropdownMenu,
    Header,
    Icon,
    Label,
    LabelGroup,
    Popup,
    Segment,
} from "semantic-ui-react"
import { DeviceModel, DeviceModelWithSteeringProfileType } from "../../store/types/deviceModel"
import { useState } from "react"
import { SteeringProfileDetails, SteeringProfileType } from "../../store/types/steering"

export interface SteeringProfileTargetDeviceModelsProps {
    allDeviceModels: DeviceModelWithSteeringProfileType[]
    selectedProfile: SteeringProfileDetails
    onChange: (data: DeviceModel[]) => void
}

export const SteeringProfileTargetDeviceModels = ({
    allDeviceModels,
    selectedProfile,
    onChange,
}: SteeringProfileTargetDeviceModelsProps) => {
    const [editedDeviceModels, setEditedDeviceModels] = useState<DeviceModel[]>(selectedProfile.targetDeviceModels)

    const compatibleDeviceModels = allDeviceModels.filter(
        (d) => d.supportedSteeringProfileType === selectedProfile.profileType,
    )

    const newDeviceModelOptions = compatibleDeviceModels
        .filter(
            (d) =>
                editedDeviceModels.find((v) => d.manufacturer === v.manufacturer && d.modelName === v.modelName) ===
                undefined,
        )
        .map((d) => {
            return {
                key: d.modelName,
                text: d.modelName,
                value: d.modelName,
                modelData: d,
            }
        })

    const updateDeviceModels = (deviceModels: DeviceModel[]) => {
        setEditedDeviceModels(deviceModels)
        onChange(deviceModels)
    }

    return (
        <Segment>
            <Header size="small">Device Models</Header>
            {editedDeviceModels.length > 0
                ? editedDeviceModels.map((d) => (
                      <Label
                          content={d.modelName}
                          onRemove={(ev, data) => {
                              updateDeviceModels(editedDeviceModels.filter((ed) => ed.modelName !== d.modelName))
                          }}
                      ></Label>
                  ))
                : "No device model is assigned to this profile "}
            {newDeviceModelOptions.length > 0 && (
                <Dropdown icon="add" floating>
                    <DropdownMenu>
                        <DropdownHeader content="Add Device Model" />
                        {newDeviceModelOptions.map((option) => (
                            <DropdownItem
                                key={option.key}
                                text={option.text}
                                value={option.value}
                                onClick={(ev, data) => {
                                    updateDeviceModels([...editedDeviceModels, option.modelData])
                                }}
                            />
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </Segment>
    )
}
