import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../../utils/api"
import { RootState } from "../../store"
import { Opal2SteeringModifiableParameters, Opal2SteeringParameters } from "../../types/steering"

type UpdateOpal2BandsteerParameters = {
    [key in keyof Opal2SteeringModifiableParameters]?: number
}

export const getOpal2BandsteerParameters = createAsyncThunk(
    "getOpal2BandsteerParameters",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/steering/parameters`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2SteeringParameters
    },
)

export interface UpdateOpal2BandsteerParametersPayload {
    serialNumber: string
    data: UpdateOpal2BandsteerParameters
}

export const updateOpal2BandsteerParameters = createAsyncThunk(
    "updateOpal2BandsteerParameters",
    async (payload: UpdateOpal2BandsteerParametersPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/steering/parameters`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.data),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2SteeringParameters
    },
)

export const restoreOpal2BandsteerParameters = createAsyncThunk(
    "restoreOpal2BandsteerParameters",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/steering/parameters/restore-defaults`, {
            method: "POST",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2SteeringParameters
    },
)

interface BandsteerParametersState {
    parameters: Opal2SteeringParameters | null
    loading: boolean
    lastError: string | null
    lastSuccess: string | null
}

function createInitialState(): BandsteerParametersState {
    return {
        parameters: null,
        loading: false,
        lastError: null,
        lastSuccess: null,
    }
}

export const SteeringSlice = createSlice({
    name: "Steering",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastSuccess = null
            state.lastError = null
        })
        builder.addCase(getOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
        })
        builder.addCase(getOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to get Steering parameters"
        })

        builder.addCase(updateOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastError = null
        })
        builder.addCase(updateOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
            state.lastSuccess = "Updated Steering parameters"
        })
        builder.addCase(updateOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to update Steering parameters"
        })

        builder.addCase(restoreOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastError = null
        })
        builder.addCase(restoreOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
            state.lastSuccess = "Restored Steering parameters"
        })
        builder.addCase(restoreOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to restore defaults"
        })
    },
})

export const steeringReducer = SteeringSlice.reducer

export const selectSteering = (state: RootState) => state.steering
