import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Dimmer, Header, Icon, Label, Loader, Message, Table } from "semantic-ui-react"
import {
    getSteeringProfile,
    listSteeringProfiles,
    openCreateSteeringProfileModal,
    selectSteeringProfiles,
} from "../../store/slices/steering/steeringProfilesSlice"
import { AppDispatch } from "../../store/store"
import { SteeringProfileLabel } from "../common/SteeringProfileLabel"
import { CreateSteeringProfileModal } from "./CreateSteeringProfileModal"
import { Link } from "react-router-dom"
import { listDeviceModels } from "../../store/slices/deviceModelsSlice"

export const SteeringProfilesTable = () => {
    const dispatch = useDispatch<AppDispatch>()
    const steeringProfilesState = useSelector(selectSteeringProfiles)

    const profiles = steeringProfilesState.steeringProfiles

    const reloadSteeringProfiles = () => {
        dispatch(listSteeringProfiles())
    }

    useEffect(() => {
        reloadSteeringProfiles()
    }, [])

    useEffect(() => {
        if (steeringProfilesState.steeringProfilesNeedReload) {
            reloadSteeringProfiles()
        }
    }, [steeringProfilesState.steeringProfilesNeedReload])

    return (
        <div>
            <Dimmer inverted active={steeringProfilesState.loading}>
                <Loader active={steeringProfilesState.loading} content="Loading" />
            </Dimmer>
            <div style={{ display: "flex" }}>
                <Header>Steering Profiles</Header>
                <div style={{ marginLeft: "auto" }}>
                    <Button circular icon="refresh" onClick={reloadSteeringProfiles} />
                </div>
            </div>
            {steeringProfilesState.lastError && (
                <Message error header="Error" content={steeringProfilesState.lastError} />
            )}
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Target Models</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {profiles.map((p) => (
                        <Table.Row>
                            <Table.Cell>
                                <Link to={`/admin/steering-profiles/${p.id}`}>{p.name}</Link>
                            </Table.Cell>
                            <Table.Cell>
                                <SteeringProfileLabel profileType={p.profileType} />
                            </Table.Cell>
                            <Table.Cell>
                                {p.targetDeviceModels.map((deviceModel) => (
                                    <Label>{deviceModel.modelName}</Label>
                                ))}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Button
                size="small"
                circular
                onClick={() => {
                    dispatch(openCreateSteeringProfileModal())
                }}
            >
                <Icon name="add" />
                Create
            </Button>
            <CreateSteeringProfileModal />
        </div>
    )
}
