import {
    Form,
    Grid,
    GridColumn,
    GridRow,
    Input,
    Label,
    SemanticWIDTHSNUMBER,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "semantic-ui-react"

export interface ObjectParametersListProps<T extends object> {
    params: T
    columns: SemanticWIDTHSNUMBER
}

export const ObjectParametersList = <T extends object>({ params, columns }: ObjectParametersListProps<T>) => {
    const keys = Object.keys(params) as Array<keyof T>
    const perColumnCount = Math.ceil(keys.length / columns)

    return (
        <Grid divided="vertically">
            <GridRow columns={columns}>
                {[...Array(columns).keys()].map((colIdx) => (
                    <GridColumn>
                        <Table basic="very">
                            <TableBody>
                                {keys.slice(colIdx * perColumnCount, (colIdx + 1) * perColumnCount).map((key) => (
                                    <TableRow>
                                        <TableCell>
                                            <b>{key.toString()}</b>
                                        </TableCell>
                                        <TableCell>{String(params[key])}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </GridColumn>
                ))}
            </GridRow>
        </Grid>
    )
}
