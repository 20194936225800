import { ReactNode, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Accordion, Button, Checkbox, Dropdown, DropdownItemProps, Form, Header, Segment } from "semantic-ui-react"
import {
    getDeviceModel,
    selectDeviceModelInfo,
    updateDeviceModelJobConfig,
    UpdateDeviceModelJobConfigParams,
} from "../../store/slices/deviceModelInfoSlice"
import { listSteeringProfiles, selectSteeringProfiles } from "../../store/slices/steering/steeringProfilesSlice"
import { AppDispatch } from "../../store/store"
import { DeviceModel, DeviceModelInfo } from "../../store/types/deviceModel"
import { Opal2SteeringModifiableParameters, SteeringProfile, SteeringProfileType } from "../../store/types/steering"
import { ObjectParametersList } from "../common/ObjectParametersList"
import { useParams } from "react-router-dom"

function getDropdownOptions(
    deviceModel: DeviceModelInfo | null,
    steeringProfiles: SteeringProfile[] | null,
): DropdownItemProps[] {
    if (deviceModel === null || steeringProfiles === null) {
        return []
    }

    const profileDropdownOptions = steeringProfiles
        .filter((p) => p.profileType === deviceModel.supportedSteeringProfileType)
        .map((p) => ({
            key: p.id,
            text: p.name,
            value: p.id,
        }))

    return profileDropdownOptions
}

function showDefaultSteeringParameters(deviceModelInfo: DeviceModelInfo | null): ReactNode {
    if (deviceModelInfo && deviceModelInfo.defaultSteeringParameters) {
        let { type, ...paramsWithoutType } = deviceModelInfo.defaultSteeringParameters
        switch (deviceModelInfo.supportedSteeringProfileType) {
            case SteeringProfileType.OPAL2:
                return (
                    <Segment>
                        <ObjectParametersList<Opal2SteeringModifiableParameters>
                            columns={2}
                            params={paramsWithoutType}
                        />
                    </Segment>
                )
            case null:
                return "No default parameters found"
            default:
                return "Unknown steering parameters type"
        }
    }
    return "No default parameters found"
}

export interface DeviceModelSteeringConfigProps {
    deviceModel: DeviceModelInfo
    steeringProfiles: SteeringProfile[]
}

export const DeviceModelSteeringConfig = ({ deviceModel, steeringProfiles }: DeviceModelSteeringConfigProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [steeringEnabled, setSteeringEnabled] = useState<boolean>(deviceModel.steeringEnabled)
    const [selectedSteeringProfile, setSelectedSteeringProfile] = useState<number | null>(deviceModel.steeringProfileId)

    const dirty =
        steeringEnabled !== deviceModel.steeringEnabled || selectedSteeringProfile !== deviceModel.steeringProfileId

    const saveSteeringConfig = () => {
        let params: UpdateDeviceModelJobConfigParams = {}
        if (steeringEnabled !== deviceModel.steeringEnabled) {
            params.steeringEnabled = steeringEnabled
        }
        if (selectedSteeringProfile !== deviceModel.steeringProfileId) {
            params.steeringProfileId =
                selectedSteeringProfile === null ? { useDefault: true } : { value: selectedSteeringProfile }
        }
        dispatch(
            updateDeviceModelJobConfig({
                deviceModel: deviceModel,
                params: params,
            }),
        )
    }

    const discardEditData = () => {
        setSelectedSteeringProfile(deviceModel.steeringProfileId)
        setSteeringEnabled(deviceModel.steeringEnabled)
    }

    const profileDropdownOptions = getDropdownOptions(deviceModel, steeringProfiles)

    return (
        <Segment>
            <Form>
                <Header>Steering Configuration</Header>
                <Form.Field>
                    <label>Enable</label>
                    <Checkbox
                        toggle
                        checked={steeringEnabled}
                        onChange={(ev, data) => setSteeringEnabled(!steeringEnabled)}
                    />
                </Form.Field>
                <Form.Group inline>
                    <Form.Field>
                        <label>Assigned Profile</label>
                        <Dropdown
                            selection
                            placeholder={
                                deviceModel.defaultSteeringParameters
                                    ? "(Default Parameters)"
                                    : "(No default parameters found)"
                            }
                            options={profileDropdownOptions}
                            value={selectedSteeringProfile ?? ""}
                            noResultsMessage="No compatible profiles"
                            onChange={(ev, data) => {
                                console.log(JSON.stringify(data))
                                setSelectedSteeringProfile((data.value as number) ?? null)
                            }}
                        />
                    </Form.Field>
                    {selectedSteeringProfile !== null && (
                        <Form.Button onClick={() => setSelectedSteeringProfile(null)}>Unassign</Form.Button>
                    )}
                </Form.Group>
            </Form>
            <Accordion
                styled
                fluid
                panels={[
                    {
                        key: "defaultParametersAccordion",
                        title: "Default Parameters",
                        content: {
                            content: showDefaultSteeringParameters(deviceModel),
                        },
                    },
                ]}
            />
            <div style={{ display: "flex", marginTop: "10px" }}>
                {dirty && (
                    <div style={{ marginLeft: "auto" }}>
                        <Button onClick={saveSteeringConfig}>Save</Button>
                        <Button onClick={discardEditData}>Discard</Button>
                    </div>
                )}
            </div>
        </Segment>
    )
}

export const DeviceModelConfig = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { manufacturer, modelName } = useParams()
    const deviceModelInfoState = useSelector(selectDeviceModelInfo)
    const steeringProfilesState = useSelector(selectSteeringProfiles)

    const deviceModel: DeviceModel = {
        manufacturer: manufacturer as string,
        modelName: modelName as string,
    }

    const deviceModelInfo = deviceModelInfoState.deviceModel
    const steeringProfiles = steeringProfilesState.steeringProfiles

    useEffect(() => {
        dispatch(getDeviceModel(deviceModel))
        dispatch(listSteeringProfiles())
    }, [manufacturer, modelName])

    useEffect(() => {
        if (deviceModelInfoState.needReload) {
            dispatch(getDeviceModel(deviceModel))
        }
    }, [deviceModelInfoState.needReload])

    return (
        <>
            <Header>
                {deviceModelInfo ? `${deviceModelInfo.manufacturer} ${deviceModelInfo.modelName}` : "Unknown Device"}
            </Header>
            {deviceModelInfo && (
                <DeviceModelSteeringConfig
                    key="deviceModelSteeringConfig"
                    deviceModel={deviceModelInfo}
                    steeringProfiles={steeringProfiles}
                />
            )}
        </>
    )
}
