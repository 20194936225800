import { Label, SemanticCOLORS } from "semantic-ui-react"
import { DevicePlatformType } from "../../store/types/deviceModel"

function getProfileTypeColor(platformType: DevicePlatformType | null): SemanticCOLORS {
    if (platformType === DevicePlatformType.OPAL2) return "yellow"

    return "red"
}

export const DevicePlatformLabel = ({ platformType }: { platformType: DevicePlatformType | null }) => {
    return (
        <>
            {platformType === null ? (
                <Label color="grey">N/A</Label>
            ) : (
                <Label color={getProfileTypeColor(platformType)}>{platformType}</Label>
            )}
        </>
    )
}
