import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"
import { WifiBand } from "./radioSlice"
import { DayOfWeek } from "../types/dayOfWeek"
import { DeviceModelInfo } from "../types/deviceModel"

export interface DeviceModelsState {
    loading: boolean
    lastError: string | null
    deviceModels: DeviceModelInfo[] | null
}

export interface SelectedDeviceModel {
    loading: boolean
    lastError: string | null
}

function createInitialState(): DeviceModelsState {
    return {
        loading: false,
        lastError: null,
        deviceModels: null,
    }
}

export interface ListDeviceModelsResponse {
    deviceModels: DeviceModelInfo[]
}

export const listDeviceModels = createAsyncThunk("listDeviceModels", async (_, { rejectWithValue }) => {
    let rsp = await tryFetch("/admin/device-models", {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as ListDeviceModelsResponse
})

export const deviceModelsSlice = createSlice({
    name: "deviceModelsSlice",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listDeviceModels.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(listDeviceModels.fulfilled, (state, action) => {
            state.loading = false
            state.lastError = null
            state.deviceModels = action.payload.deviceModels
        })
        builder.addCase(listDeviceModels.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to get device models"
        })
    },
})

export const deviceModelsReducer = deviceModelsSlice.reducer

export const selectDeviceModels = (state: RootState) => state.deviceModels
