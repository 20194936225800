import { NavLink, Outlet, Route, Routes, useLocation } from "react-router-dom"
import { Grid, Header, Segment, Tab, TabPane } from "semantic-ui-react"
import { DeviceModelsPage } from "./DeviceModelsPage"
import { SettingsView } from "./SettingsView"
import { SteeringProfilesPage } from "./SteeringProfilesPage"
import { ReactNode } from "react"

interface AdminPaneProps {
    title: string
    path: string
    key: string
    render: () => ReactNode
}

const adminPanes = [
    {
        title: "Global Settings",
        path: "/admin/global-settings",
        key: "globalSettings",
    },
    {
        title: "Device Models",
        path: "/admin/device-models",
        key: "deviceModels",
    },
    {
        title: "Steering Profiles",
        path: "/admin/steering-profiles",
        key: "steeringProfiles",
    },
]

export const AdminView = () => {
    const location = useLocation()

    const panes = adminPanes.map((p) => ({
        menuItem: {
            as: NavLink,
            id: p.key,
            content: p.title,
            to: p.path,
            exact: true,
            key: p.key,
        },
        render: () => (
            <TabPane>
                <Outlet />
            </TabPane>
        ),
    }))

    const activeIndex = adminPanes.findIndex((p) => location.pathname.startsWith(p.path))

    return (
        <Grid columns={3}>
            <Grid.Column width={1} />
            <Grid.Column width={14}>
                <Segment>
                    <Header>Admin</Header>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Tab
                                    menu={{ fluid: true, vertical: true }}
                                    grid={{ paneWidth: 14, tabWidth: 2 }}
                                    panes={panes}
                                    activeIndex={activeIndex}
                                />
                            }
                        >
                            <Route path="global-settings" element={<SettingsView />} />
                            <Route path="device-models/*" element={<DeviceModelsPage />} />
                            <Route path="steering-profiles/*" element={<SteeringProfilesPage />} />
                        </Route>
                    </Routes>
                </Segment>
            </Grid.Column>
            <Grid.Column width={1} />
        </Grid>
    )
}
