import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Route, Routes, useNavigate } from "react-router-dom"
import { Grid, Icon, Menu } from "semantic-ui-react"
import CpeView from "./components/CpeView"
import CpesListView from "./components/CpesListView"
import { AuthStatus, logout, selectAuthState } from "./store/slices/authSlice"
import { AppDispatch } from "./store/store"
import StatsView from "./components/StatsView"
import { AdminView } from "./components/admin/AdminView"
import { jwtDecode } from "jwt-decode"
import { decodeAccessToken } from "./utils/jwt"

const CpesView = () => (
    <Grid columns={3}>
        <Grid.Column width={1} />
        <Grid.Column width={14}>
            <Routes>
                <Route index element={<CpesListView />} />
                <Route path=":serialNumber" element={<CpeView />} />
            </Routes>
        </Grid.Column>
        <Grid.Column width={1} />
    </Grid>
)

const TopMenu = ({ showAdmin }: { showAdmin: boolean }) => {
    const dispatch = useDispatch<AppDispatch>()

    return (
        <Menu>
            <Link to={"/"}>
                <Menu.Item>
                    <Icon name="cog" />
                    <b>Warp</b>
                </Menu.Item>
            </Link>
            <Link to={"/cpes"}>
                <Menu.Item>Devices</Menu.Item>
            </Link>
            {showAdmin && (
                <Link to={"/admin/global-settings"}>
                    <Menu.Item>Admin</Menu.Item>
                </Link>
            )}
            <Menu.Menu position="right">
                <Menu.Item onClick={() => dispatch(logout())}>Logout</Menu.Item>
            </Menu.Menu>
        </Menu>
    )
}

const App = () => {
    const authState = useSelector(selectAuthState)
    const navigate = useNavigate()

    const accessTokenStr = authState.tokens?.access
    const accessToken = accessTokenStr ? decodeAccessToken(accessTokenStr) : null
    // TODO: After proper user management implemented and roles are refactored, update this to use group/role attribute
    const showAdmin = accessToken?.upn === "admin"

    useEffect(() => {
        const accessToken = localStorage.getItem("access")
        if (accessToken === null || authState.status === AuthStatus.LoggedOut) {
            navigate("login")
        }
    }, [authState])

    return (
        <>
            <TopMenu showAdmin={showAdmin} />
            <Routes>
                <Route index element={<StatsView />} />
                <Route path="/cpes/*" element={<CpesView />} />
                {showAdmin && <Route path="/admin/*" element={<AdminView />} />}
            </Routes>
        </>
    )
}

export default App
