import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Dimmer, Form, Header, Loader, Message, Popup, Segment } from "semantic-ui-react"
import {
    deleteSteeringProfile,
    getSteeringProfile,
    listSteeringProfiles,
    selectSteeringProfiles,
    updateSteeringProfile,
    UpdateSteeringProfileRequest,
} from "../../store/slices/steering/steeringProfilesSlice"
import { AppDispatch } from "../../store/store"
import { DeviceModel } from "../../store/types/deviceModel"
import { Opal2SteeringModifiableParameters, SteeringParameters, SteeringProfileType } from "../../store/types/steering"
import { FormError, ObjectValidator, ValidationResult } from "../../utils/editState"
import { FormObjectEdit } from "../common/FormObjectEdit"
import { SteeringProfileLabel } from "../common/SteeringProfileLabel"
import { SteeringProfileTargetDeviceModels } from "./SteeringProfileTargetDeviceModels"

const numberFieldValidator = (v: string): ValidationResult<number, FormError> => {
    if (Number(v) && !v.includes(".")) {
        return {
            value: Number(v),
        }
    } else {
        return {
            error: {
                content: "Invalid value",
                pointing: "above",
            },
        }
    }
}

const opal2Validator: ObjectValidator<Opal2SteeringModifiableParameters, FormError> = {
    phyRateDrop: numberFieldValidator,
    currThRcpi: numberFieldValidator,
    currThRcpiBouncing: numberFieldValidator,
    rateGain: numberFieldValidator,
    count11vReject: numberFieldValidator,
    count11vTimeout: numberFieldValidator,
    farRssi5G: numberFieldValidator,
    rssi5Gto2G: numberFieldValidator,
    rssi6Gto2G: numberFieldValidator,
    rssi6Gto5G: numberFieldValidator,
    staleAgeRcpi: numberFieldValidator,
    btmSteeringDisallowedTimer: numberFieldValidator,
    nonSteerableTimer: numberFieldValidator,
}

export const SteeringProfileEdit = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { profileId } = useParams()
    const steeringProfilesState = useSelector(selectSteeringProfiles)
    const [paramEditRevision, setParamEditRevision] = useState<number>(0)
    const [editedParams, setEditedParams] = useState<SteeringParameters | null>(null)
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [editedDeviceModels, setEditedDeviceModels] = useState<DeviceModel[] | null>(null)

    const steeringProfileId = Number.parseInt(profileId as string)

    const dirty = editedParams !== null || editedDeviceModels !== null

    const selectedProfile = steeringProfilesState.selectedProfile

    const updateParameters = () => {
        if (!hasErrors && selectedProfile !== null) {
            let updateData: UpdateSteeringProfileRequest = {}
            if (editedParams) {
                updateData.parameters = editedParams
            }
            if (editedDeviceModels) {
                updateData.targetDeviceModels = editedDeviceModels
            }
            if (updateData) {
                dispatch(
                    updateSteeringProfile({
                        id: selectedProfile.id,
                        updates: updateData,
                    }),
                )
            }
        }
    }

    const discardEditData = () => {
        setEditedParams(null)
        setHasErrors(false)
        setEditedDeviceModels(null)
        setParamEditRevision(paramEditRevision + 1)
    }

    const reloadProfile = async () => {
        const result = await dispatch(
            getSteeringProfile({
                profileId: steeringProfileId,
            }),
        )
        if (result.meta.requestStatus === "fulfilled") {
            discardEditData()
        }
    }

    const deleteProfile = async () => {
        const result = await dispatch(
            deleteSteeringProfile({
                profileId: steeringProfileId,
            }),
        )
        if (result.meta.requestStatus === "fulfilled") {
            navigate("/admin/steering-profiles")
        }
    }

    useEffect(() => {
        if (steeringProfilesState.selectedProfileNeedReload) {
            reloadProfile()
        }
    }, [steeringProfilesState.selectedProfileNeedReload])

    useEffect(() => {
        reloadProfile()
        if (steeringProfilesState.deviceModels.length === 0) {
            dispatch(listSteeringProfiles())
        }
    }, [steeringProfileId])

    return (
        <>
            <div>
                <Dimmer inverted active={steeringProfilesState.selectedProfileLoading}>
                    <Loader active={steeringProfilesState.selectedProfileLoading} content="Loading" />
                </Dimmer>
                {selectedProfile !== null && (
                    <>
                        <div style={{ display: "flex" }}>
                            <Header>
                                {selectedProfile.name}
                                <SteeringProfileLabel size="small" profileType={selectedProfile.profileType} />
                            </Header>
                            <div style={{ marginLeft: "auto" }}>
                                <Popup trigger={<Button circular icon="delete" onClick={deleteProfile} />}>
                                    Delete
                                </Popup>
                                <Popup trigger={<Button circular icon="refresh" onClick={reloadProfile} />}>
                                    Reload
                                </Popup>
                            </div>
                        </div>
                        <SteeringProfileTargetDeviceModels
                            key={`steeringProfileTargetDeviceModels${paramEditRevision}`}
                            allDeviceModels={steeringProfilesState.deviceModels}
                            selectedProfile={selectedProfile}
                            onChange={(deviceModels) => setEditedDeviceModels(deviceModels)}
                        />
                        <Segment>
                            <Form>
                                <Header size="small">Parameters</Header>
                                {steeringProfilesState.profileEditLastSuccess && (
                                    <Message
                                        success
                                        header="Success"
                                        content={steeringProfilesState.profileEditLastSuccess}
                                    />
                                )}
                                {steeringProfilesState.profileEditLastError && (
                                    <Message
                                        error
                                        header="Error"
                                        content={steeringProfilesState.profileEditLastError}
                                    />
                                )}
                                <FormObjectEdit<Opal2SteeringModifiableParameters>
                                    key={`steeringParametersEdit${paramEditRevision}`}
                                    params={selectedProfile.parameters}
                                    validator={opal2Validator}
                                    onStateChange={(data, hasErrors) => {
                                        setEditedParams({
                                            type: SteeringProfileType.OPAL2,
                                            ...data,
                                        })
                                        setHasErrors(hasErrors)
                                    }}
                                />
                                <div style={{ display: "flex" }}>
                                    {dirty && (
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button onClick={updateParameters} disabled={hasErrors}>
                                                Save
                                            </Button>
                                            <Button onClick={discardEditData}>Discard</Button>
                                        </div>
                                    )}
                                </div>
                            </Form>
                        </Segment>
                    </>
                )}
            </div>
        </>
    )
}
