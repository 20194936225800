import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../store/store"
import { useState } from "react"
import { SteeringProfileType } from "../../store/types/steering"
import { Button, Form, FormDropdown, FormInput, Message, Modal, ModalProps } from "semantic-ui-react"
import {
    closeCreateSteeringProfileModal,
    createSteeringProfile,
    selectSteeringProfiles,
} from "../../store/slices/steering/steeringProfilesSlice"

export interface CreateSteeringProfileModalProps {
    open: boolean
    onClose: () => void
}

const profileTypeOptions = [
    {
        key: SteeringProfileType.OPAL2,
        text: SteeringProfileType.OPAL2,
        value: SteeringProfileType.OPAL2,
    },
]

export const CreateSteeringProfileModal = () => {
    const dispatch = useDispatch<AppDispatch>()
    const state = useSelector(selectSteeringProfiles)
    const [name, setName] = useState<string>("New Profile")
    const [profileType, setProfileType] = useState<SteeringProfileType>(SteeringProfileType.OPAL2)

    return (
        <>
            <Modal
                onClose={() => {
                    dispatch(closeCreateSteeringProfileModal())
                }}
                open={state.createProfileModalOpen}
            >
                <Modal.Header>Create New Steering Profile</Modal.Header>
                <Modal.Content>
                    {state.createProfileLastError && (
                        <Message error header="Error" content={state.createProfileLastError} />
                    )}
                    <Form>
                        <FormInput
                            label="Name"
                            key="createModalNameInput"
                            value={name}
                            onChange={(ev, data) => {
                                setName(data.value)
                            }}
                        />
                        <FormDropdown
                            selection
                            label="Profile Type"
                            key="createModalNameProfileType"
                            options={profileTypeOptions}
                            value={profileType}
                            onChange={(ev, data) => {
                                setProfileType(data.value as SteeringProfileType)
                            }}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        onClick={() => {
                            dispatch(
                                createSteeringProfile({
                                    name: name,
                                    profileType: profileType,
                                }),
                            )
                        }}
                    >
                        Create
                    </Button>
                    <Button negative onClick={() => dispatch(closeCreateSteeringProfileModal())}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}
