import { useState } from "react"
import { EditState, ErrorState, FormError, ObjectValidator } from "../../utils/editState"
import { FormInput, Grid, GridColumn, GridRow } from "semantic-ui-react"

export interface FormObjectEditProps<T extends object> {
    params: T
    validator: ObjectValidator<T, FormError>
    onStateChange: (data: T, hasErrors: boolean) => void
}

export const FormObjectEdit = <T extends object>({ params, validator, onStateChange }: FormObjectEditProps<T>) => {
    const [state, setState] = useState<T>({ ...params })
    const [editData, setEditData] = useState<EditState<T>>({})
    const [errors, setErrors] = useState<ErrorState<T, FormError>>({})

    const updateData = (key: keyof T, value: string) => {
        let updatedErrors = { ...errors }
        let updatedState = { ...state }

        const res = validator[key as keyof T](value as string)
        updatedErrors[key] = res.error
        if (res.value !== undefined) {
            updatedState[key] = res.value
        }

        setErrors(updatedErrors)
        setEditData({ ...editData, [key]: value })
        setState(updatedState)
        onStateChange(updatedState, Object.keys(updatedErrors).length === 0)
    }

    const COLUMN_COUNT = 2
    const keys = Object.keys(validator) as Array<keyof T>
    const perColumnCount = Math.ceil(keys.length / COLUMN_COUNT)

    return (
        <Grid divided="vertically">
            <GridRow columns={2}>
                {[...Array(COLUMN_COUNT).keys()].map((colIdx) => (
                    <GridColumn>
                        {keys.slice(colIdx * perColumnCount, (colIdx + 1) * perColumnCount).map((key) => (
                            <FormInput
                                label={key.toString()}
                                key={key.toString()}
                                value={editData[key] ?? String(params[key])}
                                error={errors[key]}
                                onChange={(ev, data) => {
                                    updateData(key, data.value)
                                }}
                            />
                        ))}
                    </GridColumn>
                ))}
            </GridRow>
        </Grid>
    )
}
