import { Route, Routes } from "react-router-dom"
import { DeviceModelConfig } from "./DeviceModelConfig"
import { DeviceModelsTable } from "./DeviceModelsTable"

export const DeviceModelsPage = () => {
    return (
        <Routes>
            <Route index element={<DeviceModelsTable />} />
            <Route path=":manufacturer/:modelName" element={<DeviceModelConfig />} />
        </Routes>
    )
}
