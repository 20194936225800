import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Checkbox,
    Form,
    FormField,
    FormGroup,
    FormInput,
    Grid,
    GridColumn,
    Header,
    Menu,
    SemanticWIDTHS,
    Tab,
    TabPane,
} from "semantic-ui-react"
import { BSS, BSSRole, BSSUpdateParameters, bulkUpdateBSS, listBSS, selectBSS } from "../store/slices/bssSlice"
import { listRadios, selectRadios } from "../store/slices/radioSlice"
import { AppDispatch } from "../store/store"
import { WifiRadioView } from "./WifiRadioView"
import { SteeringSettingsView } from "./SteeringSettings"

interface UnifiedBSSConfig {
    main2G: BSS | undefined
    main5G: BSS | undefined
    guest2G: BSS | undefined
    guest5G: BSS | undefined
}

const findUnifiedBSSConfig = (bssMap: Record<string, BSS>): UnifiedBSSConfig => {
    return {
        main2G: bssMap["1"],
        main5G: bssMap["5"],
        guest2G: bssMap["2"],
        guest5G: bssMap["6"],
    }
}

interface BSSData {
    enabled2G: boolean
    enabled5G: boolean
    ssid: string
    isolated: boolean
}

interface BSSEditData extends BSSData {
    password: string
}

interface BSSInfo extends BSSData {
    index2G: string
    index5G: string
    role: BSSRole | null
}

interface BSSViewProps {
    serialNumber: string
    bss: BSSInfo
}

export const BSSView = ({ serialNumber, bss }: BSSViewProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [dirty, setDirty] = useState(false)
    const [editData, setEditData] = useState<BSSEditData>({
        enabled2G: bss.enabled2G,
        enabled5G: bss.enabled5G,
        ssid: bss.ssid,
        isolated: bss.isolated,
        password: "",
    })

    const updateData = (data: BSSEditData) => {
        setEditData(data)
        setDirty(true)
    }

    const updateBSS = () => {
        let updates24: BSSUpdateParameters = { id: bss.index2G }
        let updates5: BSSUpdateParameters = { id: bss.index5G }
        if (editData.enabled2G !== bss.enabled2G) {
            updates24.enabled = editData.enabled2G
        }
        if (editData.enabled5G !== bss.enabled5G) {
            updates5.enabled = editData.enabled5G
        }
        if (editData.ssid !== bss.ssid) {
            updates24.ssid = editData.ssid
            updates5.ssid = editData.ssid
        }
        if (editData.password !== "") {
            updates24.passphrase = editData.password
            updates5.passphrase = editData.password
        }
        if (bss.role === "Guest" && editData.isolated !== bss.isolated) {
            updates24.isolated = editData.isolated
            updates5.isolated = editData.isolated
        }

        dispatch(
            bulkUpdateBSS({
                serialNumber: serialNumber,
                data: {
                    updates: [updates24, updates5],
                },
            }),
        )
    }

    return (
        <Form>
            <Header>{bss.role}</Header>
            <FormGroup>
                <FormField>
                    <label>Enable 2.4 GHz</label>
                    <Checkbox
                        checked={editData.enabled2G}
                        onChange={(ev, data) => updateData({ ...editData, enabled2G: !editData.enabled2G })}
                        toggle
                    />
                </FormField>
                <FormField>
                    <label>Enable 5 GHz</label>
                    <Checkbox
                        checked={editData.enabled5G}
                        onChange={(ev, data) => updateData({ ...editData, enabled5G: !editData.enabled5G })}
                        toggle
                    />
                </FormField>
            </FormGroup>

            <FormInput
                label="SSID"
                key={`${bss.role}-SSID`}
                defaultValue={editData.ssid}
                width={6}
                onChange={(ev, data) => updateData({ ...editData, ssid: data.value })}
            />
            <FormInput
                label="Password"
                placeholder="Hidden"
                width={6}
                onChange={(ev, data) => updateData({ ...editData, password: data.value })}
            />

            {bss.role === "Guest" && (
                <FormField>
                    <label>Enable Guest Isolation</label>
                    <Checkbox
                        checked={editData.isolated}
                        onChange={(ev, data) => updateData({ ...editData, isolated: !editData.isolated })}
                        toggle
                    />
                </FormField>
            )}
            {dirty && <Button onClick={updateBSS}>Save</Button>}
        </Form>
    )
}

export const WifiConfigView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const bssConfig = useSelector(selectBSS)
    const radioConfig = useSelector(selectRadios)

    useEffect(() => {
        dispatch(listBSS({ serialNumber: serialNumber }))
        dispatch(listRadios({ serialNumber: serialNumber }))
    }, [])

    const unifiedBSS = findUnifiedBSSConfig(bssConfig.bss)

    const panes: any[] = []

    if (radioConfig.radios) {
        const radioCount = Object.keys(radioConfig.radios).length as SemanticWIDTHS
        panes.push({
            menuItem: <Menu.Item key="radio">Radio</Menu.Item>,
            render: () => (
                <TabPane key="radio">
                    <Grid columns={radioCount}>
                        {Object.values(radioConfig.radios).map((radio) => (
                            <GridColumn>
                                <WifiRadioView serialNumber={serialNumber} radio={radio} />
                            </GridColumn>
                        ))}
                    </Grid>
                </TabPane>
            ),
        })
    }

    if (unifiedBSS.main2G && unifiedBSS.main5G) {
        panes.push({
            menuItem: <Menu.Item key="main">Main SSID</Menu.Item>,
            render: () => (
                <TabPane key="main">
                    {unifiedBSS.main2G && unifiedBSS.main5G && (
                        <BSSView
                            serialNumber={serialNumber}
                            bss={{
                                enabled2G: unifiedBSS.main2G.enabled,
                                enabled5G: unifiedBSS.main5G.enabled,
                                index2G: unifiedBSS.main2G.id,
                                index5G: unifiedBSS.main5G.id,
                                isolated: unifiedBSS.main2G.isolated,
                                role: "Main",
                                ssid: unifiedBSS.main2G.ssid,
                            }}
                        />
                    )}
                </TabPane>
            ),
        })
    }

    if (unifiedBSS.guest2G && unifiedBSS.guest5G) {
        panes.push({
            menuItem: <Menu.Item key="guest">Guest SSID</Menu.Item>,
            render: () => (
                <TabPane key="guest">
                    {unifiedBSS.guest2G && unifiedBSS.guest5G && (
                        <BSSView
                            serialNumber={serialNumber}
                            bss={{
                                enabled2G: unifiedBSS.guest2G.enabled,
                                enabled5G: unifiedBSS.guest5G.enabled,
                                index2G: unifiedBSS.guest2G.id,
                                index5G: unifiedBSS.guest5G.id,
                                isolated: unifiedBSS.guest2G.isolated,
                                role: "Guest",
                                ssid: unifiedBSS.guest2G.ssid,
                            }}
                        />
                    )}
                </TabPane>
            ),
        })
    }

    panes.push({
        menuItem: <Menu.Item key="Steering">Steering</Menu.Item>,
        render: () => (
            <TabPane key="Steering">
                <SteeringSettingsView serialNumber={serialNumber} />
            </TabPane>
        ),
    })

    return (
        <>
            <Tab menu={{ pointing: true }} panes={panes} renderActiveOnly={true} />
        </>
    )
}
