import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"
import { DeviceModel, DeviceModelInfo } from "../types/deviceModel"
import { WithDefault } from "../types/withDefault"

export interface DeviceModelInfoState {
    loading: boolean
    lastError: string | null
    needReload: boolean
    manufacturer: string | null
    modelName: string | null
    deviceModel: DeviceModelInfo | null
}

function createInitialState(): DeviceModelInfoState {
    return {
        loading: false,
        lastError: null,
        needReload: false,
        manufacturer: null,
        modelName: null,
        deviceModel: null,
    }
}

export const getDeviceModel = createAsyncThunk(
    "getDeviceModel",
    async (deviceModel: DeviceModel, { rejectWithValue }) => {
        let rsp = await tryFetch(`/admin/device-models/${deviceModel.manufacturer}/${deviceModel.modelName}`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as DeviceModelInfo
    },
)

export interface UpdateDeviceModelJobConfigParams {
    steeringEnabled?: boolean
    steeringProfileId?: WithDefault<number>
}

export interface UpdateDeviceModelJobConfigPayload {
    deviceModel: DeviceModel
    params: UpdateDeviceModelJobConfigParams
}

export const updateDeviceModelJobConfig = createAsyncThunk(
    "updateDeviceModelJobConfig",
    async (payload: UpdateDeviceModelJobConfigPayload, { rejectWithValue }) => {
        const manufacturer = payload.deviceModel.manufacturer
        const modelName = payload.deviceModel.modelName
        let rsp = await tryFetch(`/admin/device-models/${manufacturer}/${modelName}/job-config`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.params),
        })
        if (rsp.status >= 400) {
            let body = await rsp.json()
            return rejectWithValue(body)
        }
    },
)

export const deviceModelInfoSlice = createSlice({
    name: "deviceModelInfoSlice",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDeviceModel.pending, (state, action) => {
            state.loading = true
            state.needReload = false
            if (state.manufacturer !== action.meta.arg.manufacturer || state.modelName !== action.meta.arg.modelName) {
                state.deviceModel = null
            }
        })
        builder.addCase(getDeviceModel.fulfilled, (state, action) => {
            state.loading = false
            state.lastError = null
            state.deviceModel = action.payload
        })
        builder.addCase(getDeviceModel.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to get device model"
        })

        builder.addCase(updateDeviceModelJobConfig.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateDeviceModelJobConfig.fulfilled, (state, action) => {
            state.loading = false
            state.lastError = null
            state.needReload = true
        })
        builder.addCase(updateDeviceModelJobConfig.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to get update device model job configuration"
        })
    },
})

export const deviceModelInfoReducer = deviceModelInfoSlice.reducer

export const selectDeviceModelInfo = (state: RootState) => state.deviceModelInfo
