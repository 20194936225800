import { Route, Routes } from "react-router-dom"
import { SteeringProfilesTable } from "./SteeringProfilesTable"
import { SteeringProfileEdit } from "./SteeringProfileEdit"

export const SteeringProfilesPage = () => {
    return (
        <Routes>
            <Route index element={<SteeringProfilesTable />} />
            <Route path=":profileId" element={<SteeringProfileEdit />} />
        </Routes>
    )
}
