import { SteeringParameters, SteeringProfileType } from "./steering"

export enum DevicePlatformType {
    OPAL2 = "OPAL2",
}

export enum DeviceModelSteeringStatus {
    Disabled = "Disabled",
    Default = "Default",
    Enabled = "Enabled",
}

export interface DeviceModel {
    manufacturer: string
    modelName: string
}

export interface DeviceModelWithSteeringProfileType extends DeviceModel {
    supportedSteeringProfileType: SteeringProfileType | null
}

export interface DeviceModelInfo extends DeviceModel {
    platformType: DevicePlatformType | null
    supportedSteeringProfileType: SteeringProfileType | null
    steeringStatus: DeviceModelSteeringStatus | null
    steeringEnabled: boolean
    steeringProfileId: number | null
    defaultSteeringParameters: SteeringParameters | null
}
