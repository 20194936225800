import { DeviceModel } from "./deviceModel"

export enum SteeringProfileType {
    OPAL2 = "OPAL2",
}

export interface Opal2SteeringModifiableParameters {
    phyRateDrop: number
    currThRcpi: number
    currThRcpiBouncing: number
    rateGain: number
    count11vReject: number
    count11vTimeout: number
    farRssi5G: number
    rssi5Gto2G: number
    rssi6Gto2G: number
    rssi6Gto5G: number
    staleAgeRcpi: number
    btmSteeringDisallowedTimer: number
    nonSteerableTimer: number
}

export interface Opal2SteeringParameters extends Opal2SteeringModifiableParameters {
    btmSteeringDisallowedList: string
    unfriendlyStaList: string
}

export interface OPAL2SteeringParameters extends Opal2SteeringModifiableParameters {
    type: SteeringProfileType.OPAL2
}

export type SteeringParameters = OPAL2SteeringParameters

export interface SteeringProfile {
    id: number
    name: string
    profileType: SteeringProfileType
    targetDeviceModels: DeviceModel[]
}

export interface SteeringProfileDetails extends SteeringProfile {
    parameters: SteeringParameters
}
