import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "semantic-ui-react"
import { listDeviceModels, selectDeviceModels } from "../../store/slices/deviceModelsSlice"
import { AppDispatch } from "../../store/store"
import { DevicePlatformLabel } from "../common/DevicePlatformLabel"
import { Link } from "react-router-dom"
import { listSteeringProfiles } from "../../store/slices/steering/steeringProfilesSlice"

export const DeviceModelsTable = () => {
    const dispatch = useDispatch<AppDispatch>()
    const state = useSelector(selectDeviceModels)

    useEffect(() => {
        dispatch(listDeviceModels())
    }, [])

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCell>Model Name</Table.HeaderCell>
                <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                <Table.HeaderCell>Platform Type</Table.HeaderCell>
                <Table.HeaderCell>Steering Status</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {state?.deviceModels?.map((m) => (
                    <Table.Row>
                        <Table.Cell>
                            <Link to={`/admin/device-models/${m.manufacturer}/${m.modelName}`}>{m.modelName}</Link>
                        </Table.Cell>
                        <Table.Cell>{m.manufacturer}</Table.Cell>
                        <Table.Cell>
                            <DevicePlatformLabel platformType={m.platformType} />
                        </Table.Cell>
                        <Table.Cell>{m.steeringStatus}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}
