import { Label, SemanticCOLORS, SemanticSIZES } from "semantic-ui-react"
import { SteeringProfileType } from "../../store/types/steering"

function getProfileTypeColor(profileType: SteeringProfileType): SemanticCOLORS {
    if (profileType === SteeringProfileType.OPAL2) return "yellow"

    return "red"
}

export interface SteeringProfileLabelProps {
    profileType: SteeringProfileType
    size?: SemanticSIZES
}

export const SteeringProfileLabel = ({ profileType, size }: SteeringProfileLabelProps) => {
    return (
        <Label size={size} color={getProfileTypeColor(profileType)}>
            {profileType}
        </Label>
    )
}
