import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Checkbox, Form, FormField, FormInput, Header, Icon, Message, Popup } from "semantic-ui-react"
import {
    getDeviceJobConfig,
    selectDeviceJobConfig,
    updateDeviceJobConfig,
    UpdateDeviceJobConfig,
} from "../../store/slices/jobConfigSlice"
import { AppDispatch } from "../../store/store"
import { ObjectParamEditState, ParamEditState } from "../../utils/editState"
import { withFallback } from "../../utils/utils"
import { DeviceModelSteeringStatus } from "../../store/types/deviceModel"

function getSteeringStatusMessageForDeviceModel(steeringStatus: DeviceModelSteeringStatus | undefined): string {
    switch (steeringStatus) {
        case DeviceModelSteeringStatus.Disabled:
            return "Disabled"
        case DeviceModelSteeringStatus.Default:
            return "Uses default parameters"
        case DeviceModelSteeringStatus.Enabled:
            return "Enabled"
    }
    return "Unknown"
}

export const DeviceJobConfigView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const deviceJobConfig = useSelector(selectDeviceJobConfig)
    const [dirty, setDirty] = useState(false)
    const [editData, setEditData] = useState<ObjectParamEditState<UpdateDeviceJobConfig>>({})

    useEffect(() => {
        dispatch(getDeviceJobConfig({ serialNumber: serialNumber }))
    }, [])

    const updateData = (data: ObjectParamEditState<UpdateDeviceJobConfig>) => {
        setEditData(data)
        setDirty(true)
    }

    const discardEditData = () => {
        setEditData({})
        setDirty(false)
    }

    useEffect(() => {
        discardEditData()
    }, [deviceJobConfig.jobConfig])

    const updateParameters = () => {
        const params: UpdateDeviceJobConfig = {
            channelManagementEnabled: editData.channelManagementEnabled,
            explorationProbability: editData.explorationProbability && {
                useDefault: editData.explorationProbability.value === "",
                value: Number(editData.explorationProbability.value),
            },
            steeringEnabled: editData.steeringEnabled,
        }
        dispatch(
            updateDeviceJobConfig({
                serialNumber: serialNumber,
                data: params,
            }),
        )
    }

    const validateProbability = (value: string): ParamEditState => ({
        value: value,
        error:
            value === "" || (!Number.isNaN(Number(value)) && 0.0 <= Number(value) && Number(value) <= 1.0)
                ? undefined
                : {
                      content: "Enter a value in range [0, 1] or leave empty for system default",
                      pointing: "above",
                  },
    })

    const jobConfig = deviceJobConfig.jobConfig
    const channelManagementEnabled = withFallback(
        editData.channelManagementEnabled,
        jobConfig?.channelManagementEnabled,
        false,
    )
    const steeringEnabled = withFallback(editData.steeringEnabled, jobConfig?.steeringEnabled, false)

    return (
        <>
            <Header>Smart Wi-Fi Configuration</Header>
            {deviceJobConfig.lastError != null && (
                <Message warning>
                    <Icon name="warning" />
                    {deviceJobConfig.lastError}
                </Message>
            )}
            <Form>
                <FormField>
                    <label>Enable Channel Management</label>
                    <Checkbox
                        checked={channelManagementEnabled}
                        onChange={(ev, data) =>
                            updateData({ ...editData, channelManagementEnabled: !channelManagementEnabled })
                        }
                        toggle
                    />
                </FormField>
                <FormInput
                    action={{
                        icon: "undo",
                        onClick: () => {
                            updateData({
                                ...editData,
                                explorationProbability: validateProbability(""),
                            })
                        },
                    }}
                    label="Exploration Probability"
                    key="explorationProbability"
                    value={withFallback(
                        editData.explorationProbability?.value,
                        jobConfig?.explorationProbability?.toString(),
                        "",
                    )}
                    error={editData.explorationProbability?.error}
                    placeholder="(System Default)"
                    width={6}
                    onChange={(ev, data) => {
                        updateData({
                            ...editData,
                            explorationProbability: validateProbability(data.value),
                        })
                    }}
                />
                <FormField>
                    <label>
                        Enable Steering
                        {steeringEnabled && (
                            <Popup trigger={<Icon name="info circle" />}>
                                <b>Device Model Steering Status: </b>
                                {getSteeringStatusMessageForDeviceModel(
                                    jobConfig?.deviceModelConfiguration.steeringStatus,
                                )}
                            </Popup>
                        )}
                    </label>
                    <Checkbox
                        checked={steeringEnabled}
                        onChange={(ev, data) => updateData({ ...editData, steeringEnabled: !steeringEnabled })}
                        toggle
                    />
                </FormField>
                <div>
                    {dirty && (
                        <>
                            <Button
                                onClick={updateParameters}
                                disabled={
                                    !Object.values(editData).every(
                                        (value) => typeof value === "boolean" || !value.error,
                                    )
                                }
                            >
                                Save
                            </Button>
                            <Button onClick={discardEditData}>Discard</Button>
                        </>
                    )}
                </div>
            </Form>
        </>
    )
}
