import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../../utils/api"
import { RootState } from "../../store"
import { DeviceModel, DeviceModelWithSteeringProfileType } from "../../types/deviceModel"
import { SteeringParameters, SteeringProfile, SteeringProfileDetails, SteeringProfileType } from "../../types/steering"

export interface SteeringsProfilesState {
    loading: boolean
    lastError: string | null
    steeringProfiles: SteeringProfile[]
    deviceModels: DeviceModelWithSteeringProfileType[]
    selectedProfile: SteeringProfileDetails | null
    selectedProfileLoading: boolean
    selectedProfileNeedReload: boolean
    selectedProfileLastError: string | null
    profileEditLastSuccess: string | null
    profileEditLastError: string | null
    createProfileModalOpen: boolean
    createProfileLastError: string | null
    steeringProfilesNeedReload: boolean
}

const createInitialState = (): SteeringsProfilesState => {
    return {
        steeringProfiles: [],
        deviceModels: [],
        selectedProfile: null,
        selectedProfileLoading: false,
        selectedProfileNeedReload: false,
        selectedProfileLastError: null,
        loading: false,
        lastError: null,
        profileEditLastSuccess: null,
        profileEditLastError: null,
        createProfileModalOpen: false,
        createProfileLastError: null,
        steeringProfilesNeedReload: false,
    }
}

interface ListSteeringProfilesResponse {
    profiles: SteeringProfile[]
    deviceModels: DeviceModelWithSteeringProfileType[]
}

export const listSteeringProfiles = createAsyncThunk("listSteeringProfiles", async (_, { rejectWithValue }) => {
    let rsp = await tryFetch("/admin/steering-profiles", {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as ListSteeringProfilesResponse
})

export const getSteeringProfile = createAsyncThunk(
    "getSteeringProfile",
    async ({ profileId }: { profileId: number }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/admin/steering-profiles/${profileId}`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as SteeringProfileDetails
    },
)

export interface CreateSteeringProfilePayload {
    name: string
    profileType: SteeringProfileType
}

export interface CreateSteeringProfileResponse {
    id: number
}

export const createSteeringProfile = createAsyncThunk(
    "createSteeringProfile",
    async (payload: CreateSteeringProfilePayload, { rejectWithValue }) => {
        let rsp = await tryFetch("/admin/steering-profiles", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body
    },
)

export const deleteSteeringProfile = createAsyncThunk(
    "deleteSteeringProfile",
    async ({ profileId }: { profileId: number }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/admin/steering-profiles/${profileId}`, {
            method: "DELETE",
        })
        if (rsp.status >= 400) {
            let body = await rsp.json()
            return rejectWithValue(body)
        }
    },
)

export interface UpdateSteeringProfileRequest {
    name?: string
    parameters?: SteeringParameters
    targetDeviceModels?: DeviceModel[]
}

export interface UpdateSteeringProfilePayload {
    id: number
    updates: UpdateSteeringProfileRequest
}

export const updateSteeringProfile = createAsyncThunk(
    "updateSteeringProfile",
    async (payload: UpdateSteeringProfilePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/admin/steering-profiles/${payload.id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.updates),
        })
        if (rsp.status >= 400) {
            let body = await rsp.json()
            return rejectWithValue(body)
        }
    },
)

export const steeringProfilesSlice = createSlice({
    name: "steeringProfiles",
    initialState: createInitialState(),
    reducers: {
        clearSelectedSteeringProfile: (state) => {
            state.selectedProfile = null
        },
        openCreateSteeringProfileModal: (state) => {
            state.createProfileModalOpen = true
            state.createProfileLastError = null
        },
        closeCreateSteeringProfileModal: (state) => {
            state.createProfileModalOpen = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(listSteeringProfiles.pending, (state, _) => {
            state.steeringProfilesNeedReload = false
            state.loading = true
            state.lastError = null
        })
        builder.addCase(listSteeringProfiles.fulfilled, (state, action) => {
            state.steeringProfiles = action.payload.profiles
            state.deviceModels = action.payload.deviceModels
            state.loading = false
        })
        builder.addCase(listSteeringProfiles.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Could not list steering profiles"
        })

        builder.addCase(getSteeringProfile.pending, (state, action) => {
            if (state.selectedProfile?.id !== action.meta.arg.profileId) {
                state.selectedProfile = null
            }
            state.selectedProfileNeedReload = false
            state.selectedProfileLoading = true
        })
        builder.addCase(getSteeringProfile.fulfilled, (state, action) => {
            state.selectedProfile = action.payload
            state.selectedProfileLoading = false
        })
        builder.addCase(getSteeringProfile.rejected, (state, action) => {
            state.selectedProfileLoading = false
            state.lastError = "Could not get steering profile"
        })

        builder.addCase(deleteSteeringProfile.pending, (state) => {
            state.selectedProfileLoading = true
        })
        builder.addCase(deleteSteeringProfile.fulfilled, (state, action) => {
            if (state.selectedProfile?.id === action.meta.arg.profileId) {
                state.selectedProfile = null
            }
            state.selectedProfileLoading = false
            state.steeringProfilesNeedReload = true
        })
        builder.addCase(deleteSteeringProfile.rejected, (state, action) => {
            state.selectedProfileLoading = false
            state.lastError = "Could not delete steering profile"
        })

        builder.addCase(updateSteeringProfile.pending, (state, _) => {
            state.selectedProfileLoading = true
        })
        builder.addCase(updateSteeringProfile.fulfilled, (state, action) => {
            state.selectedProfileLoading = false
            state.selectedProfileNeedReload = true
        })
        builder.addCase(updateSteeringProfile.rejected, (state, action) => {
            state.selectedProfileLoading = false
            state.selectedProfileLastError = "Could not update steering profile"
        })

        builder.addCase(createSteeringProfile.pending, (state, _) => {
            state.loading = true
            state.createProfileLastError = null
        })
        builder.addCase(createSteeringProfile.fulfilled, (state, action) => {
            state.loading = false
            state.createProfileModalOpen = false
            state.steeringProfilesNeedReload = true
        })
        builder.addCase(createSteeringProfile.rejected, (state, action) => {
            state.loading = false
            state.createProfileLastError = "Could not create steering profile"
        })
    },
})

export const steeringProfilesReducer = steeringProfilesSlice.reducer
export const { clearSelectedSteeringProfile, openCreateSteeringProfileModal, closeCreateSteeringProfileModal } =
    steeringProfilesSlice.actions

export const selectSteeringProfiles = (state: RootState) => state.steeringProfiles
export const selectCurrentSteeringProfile = (state: RootState) => state.steeringProfiles.selectedProfile
